import axios, { AxiosResponse, AxiosError } from "axios";
import Cookies from "js-cookie";

// Define custom headers

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.financecenter.sailet.pro/api"
    : "/api";

// Create an instance of Axios with custom configurations
const anAxiosApi = axios.create({
  baseURL: BASE_URL,
});

// Add request interceptor
anAxiosApi.interceptors.request.use(
  (config) => {
    const token = Cookies.get("token");
    if (token) config.headers["Authorization"] = "Bearer " + token;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Define the proceed function
const proceed = <T = any>(response: AxiosResponse<T>): AxiosResponse<T> =>
  response;

// Add response interceptor
anAxiosApi.interceptors.response.use(proceed, (error: AxiosError) => {
  if (error?.response?.status === 401 && !!error?.config) {
    Cookies.remove("token");
    console.error(error);
  }

  return Promise.reject(error);
});

export default anAxiosApi;
